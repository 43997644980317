import React from "react"

import("./layout.module.scss")

const Layout = props => {
  return (
    <> 

      <main>
        <div>{props.children}</div>
      </main>
    </>
  )
}

export default Layout