import React from "react"
import styles from "./featured.module.scss"

const Featured = () => {
  return (
    <section className={styles.featured}>
      <div className={styles.container}>
        <h1>Röntgen Electronics</h1>
        <p>Coming Soon!</p>
        <p>
            
          <a href="mailto:hello@rontgen.co.uk">hello@rontgen.co.uk</a>
        </p>
      </div>
    </section>
  )
}

export default Featured
