import React from "react"
import Layout from "../components/layout/layout"
import Featured from "../components/featured/featured"
const IndexPage = () => {
  return (
    <Layout>
      <Featured/>
      
    </Layout>
  )
}

export default IndexPage